body {
	margin: 0;
	padding: 0;

	font-family: "Satoshi", "Open Sans", sans-serif !important;
	font-variant: tabular-nums;
	font-feature-settings: "tnum";
	line-height: 1.5715;
	font-size: 14px;

	color: #323232;
	background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	color: #323232;
	font-weight: 400;
	margin-bottom: 0.9em;
}

.redactor-in iframe {
	max-width: 600px;
	width: 100%;
	margin: auto;
	border: none;
}

.redactor-in iframe[src*="youtube"] {
	aspect-ratio: 16/9;
}

@media (max-width: 600px) {
	h1 {
		font-size: 18px;
		font-weight: 600;
	}

	h2 {
		font-size: 16px;
		font-weight: 600;
	}
}

h2 {
	margin-bottom: 27px;
}

p {
	font-size: 14px;
	max-width: 500px;
}

.redactor-styles p {
	max-width: initial;
}

hr {
	border: none;
	border-bottom: 1px solid #d9d9d9;
	height: 0px;
	margin: 16px 0;
}

dl {
	padding: 6px 0 !important;
}

dt {
	font-size: 14px;
	color: #252425;
}

dd {
	font-size: 18px;
	color: #1ab87d;
}

.full-width-table {
	width: 100%;
}

th,
td {
	border-bottom: 1px solid #ddd;
	padding: 8px 8px 8px 8px;
	color: #333;
}

th {
	font-weight: 500;
}

#drift-widget {
	bottom: 88px !important;
	right: 0px !important;
}

.headerColBackground div {
	background-color: #f9f9fa;
	border-radius: 4px;
	padding: 12px 0px;
}

.headerColBackground p {
	text-align: center;
	size: 14px;
	margin-bottom: 0px;
}

.tableRowBackground {
	padding: 8px 10px;
}

.tableRowBackground:nth-child(even) {
	background-color: #fafafa;
}

.tableRowBackground p {
	margin: 0px 0;
}

.tableRowBackground .ant-col:nth-child(even) {
	text-align: right;
}

/* code highlighting css */

.hljs-comment,
.hljs-quote {
	color: #7e7887;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute,
.hljs-regexp,
.hljs-link,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class {
	color: #be4678;
}

.hljs-number,
.hljs-meta,
.hljs-built_in,
.hljs-builtin-name,
.hljs-literal,
.hljs-type,
.hljs-params {
	color: #aa573c;
}

.hljs-string,
.hljs-symbol,
.hljs-bullet {
	color: #2a9292;
}

.hljs-title,
.hljs-section {
	color: #576ddb;
}

.hljs-keyword,
.hljs-selector-tag {
	color: #955ae7;
}

.hljs-deletion,
.hljs-addition {
	color: #19171c;
	display: inline-block;
	width: 100%;
}

.hljs-deletion {
	background-color: #be4678;
}

.hljs-addition {
	background-color: #2a9292;
}

.hljs {
	display: block;
	overflow-x: auto;
	background: #19171c;
	color: #8b8792;
	padding: 0.5em;
}

.hljs-emphasis {
	font-style: italic;
}

.hljs-strong {
	font-weight: bold;
}

.clearfix::after {
	content: "";
	clear: both;
	display: table;
}

#hubspot-messages-iframe-container#hubspot-messages-iframe-container {
	bottom: 50px !important;
}

#hubspot-messages-iframe-container {
	max-height: 90% !important;
}

.redactor-box.redactor-styles-on.redactor-toolbar-on {
	border-color: #8d949e;
}
